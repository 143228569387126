var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-account"},[_c('div',{staticClass:"header-menu va-row"},[_c('div',{staticClass:"va-row"},[_c('div',{staticClass:"flex container-ddh-menu xs12 with-bottom va-row"},[_vm._m(0),_c('div',{staticClass:"flex xs6 no-padding text-right"},[_c('br'),_c('br'),_c('input',{ref:"files",staticClass:"hide",attrs:{"type":"file","id":"files"},on:{"change":function($event){return _vm.handleFileUpload()}}}),_c('router-link',{staticClass:"btn btn-change",attrs:{"to":{
              name: 'school',
              params: {
                school: _vm.emptySchool,
                typeSend: 'post',
              },
            }}},[_vm._v(" ADICIONAR PROFESSOR ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-change",on:{"click":function($event){return _vm.clickOnFile()}}},[_vm._v(" UPLOAD PLANILHA ")])],1)])]),_c('div',{staticClass:"clearfix"})]),_c('div',{staticClass:"container-dhh va-row"},[_vm._m(1),_c('div',{staticClass:"flex md12 xs12 no-padding"},[_c('div',{staticClass:"ui-grid"},[_c('div',{staticClass:"my-data-table va-row",attrs:{"id":"cards"}},[_c('div',{staticClass:"flex xs12 no-padding fiscal-notes"},[_c('div',{staticClass:"body-spacing"},[_c('div',{staticClass:"va-row"},[_c('table',{staticClass:"table table-striped first-td-padding"},[_vm._m(2),_c('tbody',_vm._l((_vm.list),function(item,id){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(item.user.name))]),_c('td',[_vm._v(_vm._s(item.user.email))]),_c('td',[_vm._v(_vm._s(item.user.phone))]),_c('td',{attrs:{"align":"right"}},[_c('span',[_c('router-link',{staticClass:"btn-change",attrs:{"to":{
                              name: 'school',
                              params: {
                                school: item,
                                typeSend: 'put',
                              },
                            }}},[_c('span',{staticClass:"fa fa-edit"})]),_vm._v("   "),_c('a',{staticClass:"btn-change",attrs:{"href":"#"},on:{"click":function($event){return _vm.deleteSchool(item.id, id)}}},[_c('span',{staticClass:"fa fa-trash"})])],1)])])}),0)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex xs6 no-padding"},[_c('h1',[_vm._v("Professores")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex md12 xs12 no-padding"},[_c('h4',[_vm._v("Professores Cadastrados")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("Nome")]),_c('td',[_vm._v("E-mail")]),_c('td',[_vm._v("Telefone")]),_c('td',{attrs:{"align":"right"}},[_vm._v("Ações")])])])
}]

export { render, staticRenderFns }