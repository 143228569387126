<template>
  <div class="my-account">
    <div class="header-menu va-row">
      <div class="va-row">
        <div class="flex container-ddh-menu xs12 with-bottom va-row">
          <div class="flex xs6 no-padding">
            <h1>Professores</h1>
          </div>
          <div class="flex xs6 no-padding text-right">
            <br /><br />
            <!-- {{emptySchool}} -->
            <input
              type="file"
              id="files"
              class="hide"
              ref="files"
              v-on:change="handleFileUpload()"
            />

            <router-link
              :to="{
                name: 'school',
                params: {
                  school: emptySchool,
                  typeSend: 'post',
                },
              }"
              class="btn btn-change"
            >
              ADICIONAR PROFESSOR
            </router-link>
            &nbsp;
            <button v-on:click="clickOnFile()" class="btn btn-change">
              UPLOAD PLANILHA
            </button>
          </div>
        </div>
        <!-- <menu-tabs
            :items="[
              {'label':'Dados Pessoais', 'slug':'my-data', 'isActive': false},
              {'label':'Meus Endereços', 'slug':'adresses', 'isActive': true},
              {'label':'Meus Cartões', 'slug':'cards', 'isActive': false}
            ]"
            @scrollTo="scrollParent"
            ref="tabs" class="container-ddh-menu">
          </menu-tabs> -->
      </div>
      <div class="clearfix"></div>
    </div>

    <div class="container-dhh va-row">
      <div class="flex md12 xs12 no-padding">
        <h4>Professores Cadastrados</h4>
      </div>

      <div class="flex md12 xs12 no-padding">
        <div class="ui-grid">
          <div class="my-data-table va-row" id="cards">
            <div class="flex xs12 no-padding fiscal-notes">
              <div class="body-spacing">
                <div class="va-row">
                  <table class="table table-striped first-td-padding">
                    <thead>
                      <tr>
                        <td>Nome</td>
                        <td>E-mail</td>
                        <td>Telefone</td>
                        <td align="right">Ações</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, id) in list" :key="id">
                        <td>{{ item.user.name }}</td>
                        <td>{{ item.user.email }}</td>
                        <td>{{ item.user.phone }}</td>

                        <td align="right">
                          <span>
                            <router-link
                              :to="{
                                name: 'school',
                                params: {
                                  school: item,
                                  typeSend: 'put',
                                },
                              }"
                              class="btn-change"
                            >
                              <span class="fa fa-edit"></span>
                            </router-link>

                            &nbsp;

                            <a
                              href="#"
                              v-on:click="deleteSchool(item.id, id)"
                              class="btn-change"
                            >
                              <span class="fa fa-trash"></span>
                            </a>

                            <!-- <a href="#" class="btn">
                              <span class="fa fa-eye"></span>
                            </a>
                            <a href="#" class="btn">
                              <span class="fa fa-edit"></span>
                            </a>
                            <a href="#" class="btn">
                              <span class="fa fa-trash"></span>
                            </a> -->
                          </span>
                        </td>
                        <!-- <td></td> -->
                      </tr>

                      <!-- <tr>
                        <td>Nancy Bo</td>
                        <td>nancy@boonweb.com</td>
                        <td>Washington</td>
                        <td align="right">280</td>
                        <td></td>
                      </tr> -->
                      <!-- <tr>
                        <td>Frederiko Lopez</td>
                        <td>fr.lopez@webmail.sp</td>
                        <td>Barcelona</td>
                        <td align="right">16</td>
                        <td></td>
                      </tr> -->
                      <!-- <tr>
                        <td>Stanley Hummer</td>
                        <td>mr_winner_2999@gmail.cb</td>
                        <td>Manchester</td>
                        <td align="right">57</td>
                        <td></td>
                      </tr> -->
                      <!-- <tr>
                        <td>Lendley Wintz</td>
                        <td>9938198146@mailster.io</td>
                        <td>Wien</td>
                        <td align="right">113</td>
                        <td></td>
                      </tr> -->
                      <!-- <tr>
                        <td>Barbara Noz</td>
                        <td>barbaranoz@mailster.io</td>
                        <td>Brussels</td>
                        <td align="right">68</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Matthew McCormick</td>
                        <td>matthew30@mail.ol</td>
                        <td>Vancouver</td>
                        <td align="right">93</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Nancy Bo</td>
                        <td>nancy@boonweb.com</td>
                        <td>Washington</td>
                        <td align="right">280</td>
                        <td></td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="flex md3 xs12 subscription-box" v-if="subscriptionsData">
              <div class="panel newsletter">
                Deseja utilizar esse endereço no Clube?<br>

                  <div v-for="(subscriptionItem, index) in subscriptionsData" :key="index">


                    <RadioButton class="box-payment box-method" :value="false" :selected="getSelectedId(subscriptionItem.id)" @click="selectSubscription(subscriptionItem.id, index)">
                      <div>{{subscriptionItem.characterName}}</div>
                    </RadioButton>
                  </div>

                  <div v-if="subscriberEditing" class="editing">
                    <button type="submit" class="btn btn-primary right" v-on:click="saveAddress()">SALVAR EDIÇÃO</button>
                  </div>

              </div>
            </div> -->
    </div>
  </div>
</template>

<script>
// import { required, minLength, between } from 'vuelidate/lib/validators'
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
const axios = require("axios");

export default {
  name: "schools",
  directives: { mask },
  components: {
    // vuescroll
    // VueScrollTo
    // DataVisualisationTab,
  },
  computed: {
    ...mapGetters({
      userCredential: "userCredential",
      // ...
    }),
  },

  props: ["school"],

  data() {
    return {
      list: null,
      emptySchool: {
        name: "",
        address: {
          district: "",
          address_number: "",
          state: "",
          city: "",
          address1: "",
          address2: "",
          zip_postal_code: "",
        },
        contact_name: "",
        contact_email: "",
        contact_phone: "",
        type: 0,
        project_id: 0,
      },

      files: "",
    };
  },

  mounted() {
    const self = this;
    console.log(this.userCredential);
    let config = {
      headers: {
        Authorization: "Bearer " + this.userCredential.token,
      },
    };

    axios
      .get(
        process.env.VUE_APP_ROOT_API +
          "/people/1?page=" +
          this.school.id +
          "&limit=100&sort=name asc&active=true&role_id=false",
        config
      )
      .then((response) => {
        self.list = response.data.data.people;
      })
      .catch((e) => {
        // this.errors.push(e)
      });
  },

  methods: {
    clickOnFile() {
      this.$refs.files.click();
    },

    submitFiles() {
      let formData = new FormData();

      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("csv", file);
      }

      axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "schools/importTeacher/" +
            this.school.id,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + this.userCredential.token,
            },
          }
        )
        .then(function () {
          alert("Upload feito com sucesso");
        })
        .catch(function () {
          alert("Erro, upload falhou");
        });
    },

    handleFileUpload() {
      this.files = this.$refs.files.files;
      this.submitFiles();
    },

    deleteSchool(id, key) {
      const self = this;

      let config = {
        headers: {
          Authorization: "Bearer " + this.userCredential.token,
        },
      };

      if (confirm("Você deseja excluir este item?")) {
        axios
          .delete(process.env.VUE_APP_ROOT_API + "/schools/" + id, config)
          .then((response) => {
            self.list.splice(key, 1);
            //self.$router.push({ name: 'schools' })
          })
          .catch((e) => {
            this.errors.push(e);
          });
      }
    },

    saveAddress() {
      const self = this;

      let config = {
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.percentCall = percentCompleted;
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      };

      this.$validator.validateAll().then((result) => {
        if (result) {
          // eslint-disable-next-line

          // this.addressEdit.nameRecipient = this.addressEdit.name + " " + this.addressEdit.lastname
          this.addressEdit.nameRecipient =
            this.addressEdit.name + " " + this.addressEdit.lastname;
          this.subscriptionSend.address = this.addressEdit;

          if (this.isCreation) {
            axios
              .post(
                process.env.VUE_APP_ROOT_API + "/customer/address",
                this.subscriptionSend,
                config
              )
              .then(function (response) {
                if (response.data.success !== false) {
                  self.$router.push({ name: "adresses" });
                  self.percentCall = 0;
                } else {
                  self.errors.push(response.data.errors[0]);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            axios
              .put(
                process.env.VUE_APP_ROOT_API + "/customer/address",
                this.subscriptionSend,
                config
              )
              .then(function (response) {
                if (response.data.success !== false) {
                  self.$router.push({ name: "adresses" });
                  self.percentCall = 0;
                } else {
                  self.errors.push(response.data.errors[0]);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        }
      });
    },

    showSubscriberEditing() {
      this.subscriberEditing = false;
    },
    hideSubscriberEditing() {
      this.subscriberEditing = false;
    },

    scrollParent(slug) {
      this.$router.push({ name: slug });
    },
  },
};
</script>
<style lang="scss" scoped>
.hide {
  display: none;
}
@media (max-width: 580px) {
  .btn-primary {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 5px;
    }
  }
}

.fiscal-notes ::v-deep .form-group:last-child {
  margin-bottom: 10px !important;
}

.error-label {
  color: red;
  text-align: left;
  margin-bottom: 10px;
}
small {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  text-indent: 15px;
  margin-top: -5px;
  display: block;
  margin-bottom: 10px;
  // padding-left: 15px;
}
h5 {
  font-family: "Nunito";
  font-size: 20px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
}
.address-info {
  margin-bottom: 15px;
}
.editing {
  margin-top: 15px;
}
.radio-button-wrapper {
  padding: 14px 11px;
  margin-top: 10px;

  @media (max-width: 990px) {
    padding: 20px 19px;
    margin-top: 10px;
  }
}
.header-menu-club {
  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.add-new-card {
  border-radius: 10px;
  border: dashed 2px #2cd3d8;
  background-color: #ffffff;
  font-family: Nunito;
  font-size: 12px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 3.5;
  letter-spacing: 0.7px;
  text-align: center;
  color: #4965bc;
  margin-bottom: 20px;
  padding: 15px 0px;
  cursor: pointer;

  .plus {
    font-family: "Nunito";
    font-size: 35px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.7px;
    text-align: center;
    color: #ffffff;
    width: 40px;
    height: 40px;
    transform: rotate(-180deg);
    border-radius: 40px;
    border: solid 1px #4965bc;
    background-color: #4965bc;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 36px;
  }
}

// Default of page

h4 {
  font-family: "Nunito";
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1.3px;
  color: #000000;
  margin-bottom: 0;
}
.header-menu {
  background-color: white;

  h1 {
    font-family: "Nunito";
    font-size: 40px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.7;
    letter-spacing: normal;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 20px;

    @media (max-width: 990px) {
      font-size: 18px;
    }
  }
}
.my-data {
  /* Facebook bar */
  .facebook-tag {
    height: 65px;
    border-radius: 5px;
    background-color: #3a5998;
    color: white;
    padding: 14px 20px;
    margin-bottom: 20px;

    svg {
      font-size: 26px;
      margin-right: 0px;
      float: left;

      path {
        fill: #fff;
      }
    }

    .name {
      display: inline-block;
      margin-top: 1px;
      line-height: 37px;
    }

    .facebook-toggle {
      float: right;
      display: block;
      margin-top: 5px;
    }
  }
}

.change-password {
  border-radius: 10px;
  border: solid 2px #f0ebf5;
  background-color: #ffffff;
  padding: 24px 20px;
  position: relative;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 7px;
    font-family: "Roboto";

    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #afa2ba;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .floated-icon {
    float: left;
    width: 36px;
    height: 36px;
    font-size: 30px;

    svg {
      position: relative;
      top: -5px;
      left: -3px;

      path {
        fill: #afa2ba;
      }
    }
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }
}

.my-data-table {
  h4 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
.fiscal-notes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  .body-spacing {
    padding: 30px 30px 10px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }

    //  div:last-child{
    //   margin-bottom: 0px;
    // }
  }

  hr {
    border-top: solid 1px #f0ebf5;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  .company-code {
    text-transform: capitalize;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  // .panel:last-child{
  //   margin-bottom: 0px;
  // }
}

.address-boxes {
  border-radius: 10px;
  border: solid 1px #f0ebf5;
  background-color: #ffffff;
  position: relative;
  border: solid 2px #f0ebf5;

  h4 {
    font-size: 16px;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Roboto";
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
  }

  svg {
    path {
      fill: #afa2ba;
    }
  }

  .clube {
    svg {
      path {
        fill: #fff;
      }
    }
  }

  .body-spacing {
    padding: 30px 30px;

    @media (max-width: 990px) {
      padding: 25px 15px 15px 15px;
    }
  }

  hr {
    border-top: solid 2px #f0ebf5;
  }

  .dual-inputs {
    padding: 0px 8px;
    margin-bottom: 20px;
  }

  .new-password-group {
    margin-top: 20px;
    display: block;
  }

  .editor {
    right: 18px;
    top: 20%;
  }

  ::v-deep .form-group {
    margin-bottom: 20px;
    padding: 22px 20px;

    ::v-deep &:last-child {
      margin-bottom: 20px;
    }
  }

  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }

  .address-row {
    padding: 16px 0px;
    position: relative;
    border-bottom: solid 1px #f0ebf5;
    b {
      font-size: 16px;
      color: #000000;
    }
    :last-child {
      border-bottom: none;
    }
  }
}

.address-row {
  position: relative;
}

.more-cards {
  .more-address {
    border-radius: 10px;
    border: solid 1px #f0ebf5;
    background-color: #ffffff;
  }
}

.panel {
  // height: 200px;
  border-radius: 10px;
  border: solid 2px #9378f0;
  background-color: #ffffff;
  overflow: hidden;
  margin-bottom: 20px;

  .header-panel {
    padding: 0px 20px;
    background-color: #9378f0;
    line-height: 33px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .clube img {
    margin-top: -5px;
  }

  b {
    font-size: 16px;
    color: #000000;
  }

  .panel-body {
    position: relative;
    padding: 10px 0px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #afa2ba;
    margin: 0px !important;

    .flex.xs1 {
      -ms-flex-preferred-size: 8.33333%;
      flex-basis: 6.33333%;
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      max-width: 6.33333%;
    }

    b {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.31;
      letter-spacing: normal;
      color: #000000;
    }

    .editor {
      right: 18px;
      top: 20%;
    }
  }
}

.subscription-box {
  // padding: 30px !important;

  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: #000000;

  .toggled {
    margin-top: 10px;
  }
}
.newsletter {
  // margin-top:40px;
  padding: 31px;
  border: solid 2px #f0ebf5;

  @media (max-width: 990px) {
    margin-top: 0px;
    padding: 15px;
  }
}

// Custom Component
.v-switch-button {
  ::v-deep &:before {
    content: "";
    display: block;
    position: relative;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 14.5px;
    margin-left: auto;
    margin-right: auto;
    top: 9px;
  }
}

.vue-js-switch {
  &.toggled {
    .v-switch-button {
      ::v-deep &:before {
        content: "";
        display: block;
        position: relative;
        background-color: #7ed321;
        width: 6px;
        height: 6px;
        border-radius: 14.5px;
        margin-left: auto;
        margin-right: auto;
        top: 9px;
      }
    }
  }
}

.name-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
.lastname-box {
  @media (max-width: 980px) {
    padding: 0px !important;
  }
}
</style>
